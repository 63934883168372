import React from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/Layout"
import useSiteMetadata from "../components/GetSiteMetaData"
import { PageSeo } from "../components/PageSeo"

const ContactUsTemplate = ({ data }) => {
  const { email } = useSiteMetadata()

  const { markdownRemark: markup } = data
  const { name, description, location } = markup.frontmatter
  return (
    <Layout>
      <section className="ContactUsTemplate container my-5">
        <div>
          <div className="">
            <h2 className="section_heading">{name}</h2>
            <p>{description}</p>
            <div>
              <a
                className="active btn btn-primary btn-sm"
                aria-current="page"
                href={`mailto:${email}`}
              >
                Send a mail <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <LocationComponent location={location} />
    </Layout>
  )
}

const LocationComponent = ({ location }) => {
  return (
    <div className="container">
      <h4 className="pb-2">Locate Our Offices</h4>
      {location.length &&
        location.map((item, index) => (
          <div
            className="card mb-3 bg-light border-0 "
            key={`location_${index}`}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-5 mb-3 mb-lg-0">
                  <iframe
                    title={item.heading}
                    src={item.iframe}
                    frameborder="0"
                    className="w-100"
                  ></iframe>
                </div>

                <div className="col-12 col-lg-6 ms-3">
                  <div className="d-flex justify-content-between align-items-center pb-2">
                    <h5 className="sub_heading pb-0 mb-0 text-primary">
                      {item.heading}
                    </h5>
                    {/* <a href="/" className="btn btn-sm btn-outline-primary">
                      {item.shareText}
                    </a> */}
                  </div>
                  <p>
                    <i className="bi bi-flag-fill"></i> {item.address}
                  </p>

                  {item.mobileNumber1 && (
                    <p className="mb-1">
                      <i className="bi bi-telephone-fill"></i>{" "}
                      {item.mobileNumber1}
                    </p>
                  )}
                

                  <div className="mt-3">
                    <Link
                      to={item.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-sm btn-outline-primary"
                    >
                      <i className="bi bi-geo-alt-fill"></i> Map view
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
LocationComponent.propTypes = {
  location: PropTypes.shape({
    heading: PropTypes.string,
    iframe: PropTypes.string,
    shareText: PropTypes.string,
    address: PropTypes.string,
    emailText: PropTypes.string,
    mobileNumber1: PropTypes.number,
    path: PropTypes.string,
  }),
}

export const ContactUsQuery = graphql`
  query ContactUsQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-us" } }) {
      id
      html
      frontmatter {
        name
        description
        blurb {
          heading
          content
        }
        location {
          heading
          iframe
          shareText
          address
          email
          mobileNumber1
          path
        }
      }
    }
  }
`
export default ContactUsTemplate
export const Head = () => (
  <PageSeo
    title="Contact Us | Ranked #1 Investment Management Software Solution Provider"
    description="Credence Analytics (India) Pvt. Ltd."
    keywords="Credence Analytics (India) Pvt. Ltd, Discover Credence Analytics, Working At Credence Analytics, Whitepapers, Case Studies, Testimonials, Press Releases, Events"
  />
)
